.formTitle {
	cursor: pointer;
}
.formTitle:hover {
	color: rgb(218, 13, 13);
}
.editForm {
	position: absolute;
	padding: 0px 30px;
	padding-bottom: 20px;
	width: 25%;
	left: 34%;
	right: 0%;
	background-color: white;
	z-index: 90;
	border: 1px solid black;
	border-radius: 10px;
}
.editForm h2 {
	text-align: center;
	font-size: 1.8vh;
}
.movieList {
	position: relative;
}
.editButton {
	margin-top: 2%;
	left: 45%;
}
td a {
	color: black;
	text-decoration: none;
}
td a:hover {
	color: rgb(39, 72, 182);
}

.reviewIcon {
	float: left;
	margin-top: 0.5rem;
	margin-right: 2%;
}
.sort {
	position: absolute;
	margin-left: 60px;
	top: 25px !important;
}
th p:hover {
	color: rgb(39, 72, 182);
	cursor: pointer;
}
th p {
	font-size: medium;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
}
.movieListArea {
	max-width: 70%;
	margin: 0 auto;
}

@media only screen and (max-width: 768px) {
	.movieListArea {
		max-width: 100%;
		padding: 3% 0px !important;
	}
	.movieListText {
		font-size: medium;
	}
	.react-confirm-alert-body {
		z-index: 100;
		margin: auto;
		width: 80% !important;
	}
	.sort {
		top: 40px !important;
	}
	.editForm {
		width: 50%;
		left: 18%;
		right: 0%;
	}
}
