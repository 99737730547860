.waves {
	top: 0;
	z-index: -1;
	position: fixed;
	width: 100%;
	height: 15vh;
	/*Fix for safari gap*/
	min-height: 100px;
	max-height: 150px;
	transform: rotate(180deg);
}

/* Animation */

.parallax > use {
	animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
	animation-delay: -2s;
	animation-duration: 7s;
}

.parallax > use:nth-child(2) {
	animation-delay: -3s;
	animation-duration: 10s;
}

.parallax > use:nth-child(3) {
	animation-delay: -4s;
	animation-duration: 13s;
}

.parallax > use:nth-child(4) {
	animation-delay: -5s;
	animation-duration: 20s;
}

@keyframes move-forever {
	0% {
		transform: translate3d(-90px, 0, 0);
	}

	100% {
		transform: translate3d(85px, 0, 0);
	}
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
	.waves {
		height: 40px;
		min-height: 40px;
	}
}
@-webkit-keyframes waterfall {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-250%);
		transform: translateY(-250%);
	}

	40%,
	60% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(250%);
		transform: translateY(250%);
	}
}

@keyframes waterfall {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-250%);
		transform: translateY(-250%);
	}

	40%,
	60% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(250%);
		transform: translateY(250%);
	}
}
.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.waterfall div {
	-webkit-animation: waterfall 1.5s infinite;
	animation: waterfall 1.5s infinite;
	background-color: rgb(45, 146, 224);
	height: 20px;
	opacity: 0;
	position: absolute;
	width: 20px;
}

.waterfall div:nth-of-type(1) {
	-webkit-animation-delay: 0.25s;
	animation-delay: 0.25s;
	margin-left: -10px;
}

.waterfall div:nth-of-type(2) {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
	margin-left: 15px;
}

.waterfall div:nth-of-type(3) {
	-webkit-animation-delay: 0.75s;
	animation-delay: 0.75s;
	margin-left: -35px;
}

.waterfall div:nth-of-type(4) {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
	margin-left: 40px;
}

.waterfall div:nth-of-type(5) {
	-webkit-animation-delay: 1.25s;
	animation-delay: 1.25s;
	margin-left: -60px;
}
@media only screen and (max-width: 768px) {
	.waves {
		top: 50px;
	}
	.App {
		margin-top: 55px;
	}
}
