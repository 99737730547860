.movies {
    --repeat: auto-fit;
    margin-right: 0 !important;
}

@media (min-width: calc(350px * 3)) {
    .movies {
        --repeat: 3;
    }
}

.movies {
    display: grid;
    /* grid-template-columns: repeat(3, 300px); */
    grid-template-columns: repeat(var(--repeat, auto-fit),
            minmax(calc(350px * 1), 1fr));
    justify-content: center;
    align-content: center;
    height: auto;
}

.movie {
    text-align: left;
    position: relative;
    padding: 20px;
    border-radius: 5px;
    height: 100%;
}
.homeSearch {
    width: 30%;
    position: absolute;
    display: inline-block;
    right: 1.5%;
    top: 0;
}
.homePagination{
    margin-left: 1.5%;
}
.movieSection{
    position: relative;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 1.5%;
}
.homeHead{
    position: relative;
}
@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .homeSearch {
        position: relative;
        width: 80%;
        left: 10%;
        margin-top: 3%;
    }
    .homePagination {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3%;
        width: 365px;
    }
    .movies {
        left: 15%;
        grid-template-columns: repeat(var(--repeat, auto-fit),
                    minmax(calc(380px * 1), 1fr));
    }
}
@media only screen and (min-width: 768px) {
    .movieSection {
        padding-left: 70px;
    }
}