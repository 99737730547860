.editAccount {
	position: absolute;
	right: 34%;
	width: 30%;
}
.editAccountInput {
	margin: 0.5rem 0px !important;
}
@media only screen and (max-width: 768px) {
	.editAccount {
		position: relative;
		right: 0%;
		width: 100%;
	}
}
