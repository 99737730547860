
.dropdown-content {
    margin-top: 1%;
    position: absolute;
    display: block;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index:99;
    width: 100%;
    
}
.searchMovie {
    color: black;
    padding: 7px 9px;
    text-decoration: none;
    display: block;
    transition: all .2s ease-in-out;
}

.searchMovie:hover {
    transform: scale(1.1);
}
.searchMovie:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}
.search{
    width:100%;
    position: relative;
}
.search fieldset, label{
    color: black !important;
    border-color: #1947ac!important;
}
