.bigCard{
    margin-top:2%;
}
.mainContent{
    display: flex;
    margin: 20px;
}
.column{
    flex: 50%;
}
.plot{
    text-align:justify;
    text-align-last:justify;
}
.extra{
    text-align: left;
}
.bigCard h1{
    width: 100%;
    margin-left: 20px;
}
.badge{
    display: inline-block;
    vertical-align: -18%
}
#smallCard{
    margin-top: 0;
    max-width: 400px;
}
.titleLink{
    text-decoration: none;
    color: #338BA8
}
.cardBody{
    margin-top: 3%;
}
.mainContent svg{
    position: relative;
    top: 4px;
    margin-right: 2%;
}
@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .mainContent{
        display: inline-block;
        margin: 0px;
    }
    .mainContent h2 {
        margin-top: 10px;
    }
    .bigCard h1{
        text-align: center;
        margin: 0px;
        margin-top: 1rem;
    }
    .badge{
    display: table;
    margin: auto;
    }
}
