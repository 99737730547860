.userInfo{
    margin-top: 4%;
    margin-bottom: 4%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.movieList{
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
align-items: center;
}
.userMovie{
    display: inline-block;
    border-radius: 10px;
    border: 1.5px solid black;
    min-width: 400px;
    padding: 10px;
}
h3{
    display: inline;
}
.status{
    float: right;
}
.movieMain{
    float: left;
}
.tab{
    width: 43%;
    margin: 0 auto;
}
@media only screen and (max-width: 768px) {

    .tab {
        width: 100%;
        margin: 0 auto;
    }
}