.navbar {
	height: 80%;
	width: 75px;
	min-height: 280px;
	position: fixed;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	margin: 0;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
nav {
	background-color: rgb(45, 146, 224);
	padding: 10px;
	width: 100%;
	text-align: center;
	/* Pay special attention here! */
	position: fixed;
	top: 0;
	z-index: 100;
	padding-left: 1.5%;
}

nav a {
	display: inline-block;
	color: white;
	text-decoration: none;
}

nav a:hover {
	text-decoration: underline;
}
@media only screen and (max-width: 768px) {
	nav button {
		display: inline !important;
		text-align: center !important;
		margin: 0px 10px !important;
		width: 30px !important;
		height: 30px !important;
	}
}
