.addMovieForm {
	width: 30%;
	margin: 0 auto;
	margin-top: 2%;
	position: relative;
}
.formTitle {
	font-weight: bold;
}
.movieFormButton {
	position: relative;
	margin-top: 0.7rem;
	left: 45%;
}
@media only screen and (max-width: 768px) {
	.addMovieForm {
		width: 90%;
		margin: 0 auto;
		margin-top: 2%;
		position: relative;
	}
}
